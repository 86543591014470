.breadcrumb-item {
  cursor: pointer;

  &:hover {
    color: #4949ec;
    text-decoration: underline;
  }
}

.da-breadcrumbs{
  margin-left: 24px;
  display:flex;
  align-items: center;
  justify-content: center;
  // .MuiBreadcrumbs-root{
  //   max-
  // }
}