.da-alert-handler{
    position:absolute;
    display:flex;
    top:10px;
    justify-content: center;
    margin:auto;
    left:0;
    right:0;
    flex-direction: column;
    pointer-events: none;
    
    > *{
        width:355px;
        align-self: center;
        margin-top:10px;
        pointer-events: all;
    }
}