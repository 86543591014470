.da-transfer{
    &-list{
        max-height: 200px;
        overflow: scroll;
        border-color: black;
        li{
            &:hover{
                background-color: gray;
                cursor: pointer;
            }

            &::selection{
                background-color:gray;
            }
        }   
        
    }
}