@import '../../sass/global.scss';

.da-empty-page{
    margin-top: 10%;

    &-inner{
        display:flex;
        align-items: center;
        justify-content: center;
    }


    &-text-block{
        margin-left:100px;
        width:275px;
        @extend .da-typography-lato;
        @extend .da-typography-color-secondary;
        @extend .da-typography-body-secondary;
        // font-family: Lato;

        &-heading{

            @extend .da-typography-color-primary;
        }
    }
}
