// @import 'colors';

.da-typography{
    &-lato{
        font-family: Lato;
    }

    &-color{
        &-primary{
            color: $type-primary;
        }

        &-secondary{
            color: $type-secondary;
        }
    }

    &-body-secondary{
        line-height: 24px;
        font-size: 15px;
        font-weight: 400;
    }
}

h6{
    font-weight: 500;
    font-size: 20px;
    margin-bottom:25px;
}