
#sidebar {
  padding-top: 10px;
}

.sidebar-item {
  padding: 2px 10px;
  cursor: pointer;

  &:hover {
    background-color: #e0e1e7;
  }
}
