.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.parent {
  width: 100%;
  height: 100%;
  position: relative;

  .MuiDrawer-docked .MuiDrawer-paper {
    top: 70px !important;
    height: calc(100vh - 70px);
  }
}

.da-sticky {
  position: sticky;
  width: 100%;
  top: 0;

  z-index: 2;
  margin-bottom: 70px;

  .da-action-bar {
    background-color: white;
    position: relative;
    top: 69px; //height of navigation bar 
  }
}
