@import '../../sass/global.scss';

.action-bar {
  padding: .9rem;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // justify-content: center;
  border-bottom: 1px solid #E0E0E0;
  .da-icon-set{


  }

  #menuButton {
    margin-left: 15px !important;
  }
}

.da-action-bar{
  &-btn-set{
    display:flex;
    align-items: center;
  }

  &-icon-set{
    .MuiSvgIcon-root{
      cursor:pointer;
      margin-right:24px;
      margin-left:24px;
      color: #555555;
      &:last-child{
        margin-right:30px;
      }
    }


  }

  &-menu{
    color:$type-secondary;
  }
}

.da-look-text{
  display:flex;
  align-items: center;
  justify-content: space-between;

  &-icon{
    color:$type-secondary;

    // FONT SIZE OF THIS ICON IS DEFINED IN THE COMPONENT ON THE SVG ICON
  }
}
