@import '../../sass/global.scss';

.da-folder-view {
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 2rem;
  cursor: default;
}

.da-folder-view-content-table {
  width: 100%;
  flex-wrap: wrap;
  border-collapse: collapse;

  th {
    text-align: left;
    padding: .5rem;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: $type-secondary
  }

  tr {
    display: table-row;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;

    td {
      padding: .5rem;
      border-top: 1px solid #dee2e6;
      &:not(:nth-child(2)) {
        color: $type-secondary;
      }
      &:first-child {
        width: .5rem;
      }
    }
  }
}

.da-selected-item {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #0097DC, #0097DC);
  color: #0069AA;
}
