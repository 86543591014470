@import '../../sass/colors';
$text-primary: hsl(0, 0%, 13%);
$list-hover: rgba(0,151,220,.04);
$list-selected: rgba(0,151,220,.08);
$green: #00A19D;
$border-style: 1px solid #E0E0E0;
.da-modal-folder-select{
    width:100%;
    padding:0;
    margin:0;

    &-item{
        flex-direction: row;
        align-content: center;
        display:flex;
        justify-content: flex-start;
        padding-top:15px;
        padding-bottom:10px;
        border-top: $border-style;
        width:100%;
        list-style-type: none;
        color: $text-primary;

        
        
        &:hover{
            background-color: $list-hover;
        }

        &--selected{
            background-color: $list-selected;
            &:hover{
                background-color: $list-selected;
            }
        }

        &:last-child{
            border-bottom: $border-style;


        }

        .da-select-item-folder-icon, .da-select-item-arrow-icon{
            color: #555555;
        }

        span{
            .MuiSvgIcon-root{
                // cursor:pointer;
                margin-right:24px;
                // margin-left:24px;
                
            }

            &.da-select-item-arrow-icon{
                margin-left:auto;
                width:24px;
                margin-right:0;
            }
        }
    }
}