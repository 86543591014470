.da-loading-container{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .da-loading{
        position: relative;
        width:128px;
        height:128px;

        &-icon{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            
            img{
                height:52px;
                width:52px;
            }
        }
    }
}