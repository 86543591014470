@import '../../sass/global.scss';

.da-icon {
  &-dash {
    color: $yellow;
    fill: currentColor
  }

  &-look {
    color: $green;
  }
}
