.da-emb-loading-container{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10%;
  .da-emb-loading{
      position: relative;
      width:128px;
      height:auto;

      &-icon{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
      }

      .da-emb-loading-img{
          display: flex;
          justify-content: center;

          img{
            width: 75px
          }
      }
  }
}